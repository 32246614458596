if ($('.customerSlider').length) {
    // Numeral init
    numeral.locale('da-dk');

    // Get cookies
    var creditValue = getCookie('creditValue');

    // Slider init
    var billingArray = [];
    $('.slider__total-detail span').each(function (i) {
        billingArray.push($(this).data('amount'));
    });
    var billingRangeMin = billingArray[0];
    var billingRangeMax = billingArray[(billingArray.length - 1)];
    if (!creditValue.length > 0) {
        creditValue = billingRangeMin;
    }
    $('#billing-slider-in').attr({
        min: billingRangeMin,
        max: billingRangeMax,
        value: creditValue
    });
    $('#billing-slider-out').text(numeral(creditValue).format("0,0"));
    $('.slider__total-detail span:first-child').addClass('active');

    // Slider update
    $('.slider__range').on('input change', 'input', function () {
        var value = $(this).val();
        $('#billing-slider-out').text(numeral(value).format("0,0"));
        setCookie('creditValue', value, 10000);

        billingInfoUpdate(billingArray, value);
    });
}

function billingInfoUpdate(ticks, val) {
    $('.slider__total-detail .active').removeClass('active');

    var sliderValue = parseInt(val);
    if (isInArray(sliderValue, ticks)) {
        index = ticks.indexOf(sliderValue);
        $('.slider__total-detail span:eq(' + index + ')').addClass('active');
    } else {
        var i = 0;
        while (i < ticks.length && ticks[i] < sliderValue) {
            $('.slider__total-detail .active').removeClass('active');

            index = ticks.indexOf(ticks[i]);
            $('.slider__total-detail span:eq(' + index + ')').addClass('active');
            i++;
        }
    }
}
