if ($('.oilStep5').length) {
    var onlineDiscount = getCookie("onlineDiscount");
    var productDiscount = getCookie("productDiscount");
    var cashDiscount = getCookie("cashDiscount");
    var campaignDiscount = getCookie("campaignDiscount");
    var literAmount = getCookie("literAmount");
    var oilTypeName = getCookie('oilTypeName') || 'Basis';
    var pricePer1kLiter = getPricePer1kLiter();
    updatePrices(literAmount, pricePer1kLiter, oilTypeName);
    if (window.location.href.indexOf("f=1") < 0) {       
            salesforce();       
    } else {
        setTimeout(function () {
            var ContactPersonFirstName = getCookie('ContactPersonFirstName');
            var ContactPersonEmail = getCookie('ContactPersonEmail');
            var order_id = getCookie('order_id');
            var salesforceId = getCookie('salesforceId');

            if (salesforceId == '' || salesforceId == '0' || salesforceId == 'undefined' || salesforceId == 'null') {

                $('.oilStep5').addClass('hide');
                $('.oilStep5E').removeClass('hide');
            }
            else {
                $('#ContactPersonFirstName').text(ContactPersonFirstName);
                $('#ContactPersonEmail').text(ContactPersonEmail);
                $('#SalesforceId').text(salesforceId);
                $('#order_id').text(salesforceId);
                $('.dynamic-DeliveryDate').text($('#enddate').text()); // dd/mm/yyyy

                $('.oilStep5').removeClass('hide');
            }
        }, 3500);
    }
   // if (window.location.href.indexOf("f=2") < 0) {



   

    
   // }
}
