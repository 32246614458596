if ($('.mosaic-section__init').length) {
    var $grid = $('.mosaic-section__init').masonry({
        itemSelector: '.mosaic-section__box',
        columnWidth: '.masonry-grid-size',
        // percentPosition: true,
    });

    var elements = document.querySelectorAll('.mosaic-section__body');
    for (i = 0; i < elements.length; i++) {
        if (i % 2) {
            elements[i].classList.add('-even');
        }
    }
}