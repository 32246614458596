// Function to update all prices in the cart.
function updatePrices(literAmount, pricePer1kLiter, oilTypeName) {
    var paymentMethod = getCookie('paymentMethod');
    var invoicePrice = getCookie('invoicePrice');
    var invoiceEmail = getCookie('invoiceEmail');
    var invoicePost = getCookie('invoicePost');
    if (invoiceEmail == "true") {
        invoicePrice = 0;
    }
    else {
        invoicePrice = invoicePrice;
    }

    if ($('.hero__image-sequence').length) {
        var ticks = createTicksArray();
        heroImageUpdate(ticks);  
    }    

    var data = {
        'pageId': document.body.dataset.pageId,
        'literAmount': literAmount,
        'pricePer1kLiter': pricePer1kLiter,
        'paymentMethod': paymentMethod,
        'invoicePrice' : invoicePrice
    };

    $.ajax({
        type: "POST",
        url: '/Umbraco/Api/Calculator/PriceCalculator',
                  
        data: data ,
        dataType: "json",
        success: function (response) {
            $('.dynamic-literAmount').text(numeral(literAmount).format("0,0"));
            $('.dynamic-subTotalPrice').text(numeral(response.subTotalPrice).format("0,0.00"));
            $('.dynamic-onlineDiscount').text(numeral(response.onlineDiscount).format("-0,0.00"));
            $('.dynamic-productDiscount').text(numeral(response.productDiscount).format("-0,0.00"));
            if (paymentMethod == 'mobilepay' || paymentMethod == 'kreditkort') {
                $('.dynamic-cashDiscount').text(numeral(response.cashDiscount).format("-0,0.00")).parent().parent().removeClass('hide');

            } else {
                $('.dynamic-cashDiscount').parent().parent().addClass('hide');
            }
            $('.dynamic-campaignDiscount').text(numeral(response.campaignDiscount).format("-0,0.00"));
            $('.dynamic-totalPrice').text(numeral(response.totalPrice).format("0,0.00"));
            $('.dynamic-totalDiscount').text(numeral(response.totalDiscount).format("0,0.00"));
            $('.dynamic-oilTypeName').text(oilTypeName);
            $('.oil__Liter').text(literAmount);
            $('.oiltype__body-price').text(numeral(response.totalPrice).format("0,0.00"));
            setCookie('subTotalPrice', response.subTotalPrice, 10000);
            setCookie('totalPrice', response.totalPrice, 10000);       // Kill this when quickpay works.
            setCookie('totalDiscount', response.totalDiscount, 10000);

            if ($('.oiltype__heading-0').text() == oilTypeName) {
                $('.oiltype__body-price-0').text(numeral(response.totalPrice).format("0,0.00"));
                pricePer1kLiter = parseFloat($('.oiltype-price-perkilo-1').text()) / 1000;
                oilType = $('.oiltype__heading-1').text();
                updateOilPrice(literAmount, pricePer1kLiter, oilType);
            }
            else {
                $('.oiltype__body-price-1').text(numeral(response.totalPrice).format("0,0.00"));
                pricePer1kLiter = parseFloat($('.oiltype-price-perkilo-0').text()) / 1000;
                oilType = $('.oiltype__heading-0').text();
                updateOilPrice(literAmount, pricePer1kLiter, oilType);
            }
        },
    });   
}

//Function to UpdatePrice for Basis and Premium  Oil Type on step 1 (we Update price for both of them)
function updateOilPrice(literAmount, pricePer1kLiter, oilTypeName) {
    var paymentMethod = getCookie('paymentMethod');
    var invoicePrice = getCookie('invoicePrice');
    var invoiceEmail = getCookie('invoiceEmail');
    var invoicePost = getCookie('invoicePost');
    if (invoiceEmail == "true") {
        invoicePrice = 0;
    }
    else {
        invoicePrice = invoicePrice;
    }

    if ($('.hero__image-sequence').length) {
        var ticks = createTicksArray();
        heroImageUpdate(ticks);
    }

    var data = {
        'pageId': document.body.dataset.pageId,
        'literAmount': literAmount,
        'pricePer1kLiter': pricePer1kLiter,
        'paymentMethod': paymentMethod,
        'invoicePrice': invoicePrice
    };

    $.ajax({
        type: "POST",
        url: '/Umbraco/Api/Calculator/PriceCalculator',
        data: data,
        dataType: "json",
        success: function (response) {
            if ($('.oiltype__heading-0').text() == oilTypeName) {
                $('.oiltype__body-price-0').text(numeral(response.totalPrice).format("0,0.00"));
            }
            else {
                $('.oiltype__body-price-1').text(numeral(response.totalPrice).format("0,0.00"));
            }
        },

    });
}