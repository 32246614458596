$(':file').on('change', function () {
    var file = this.files[0];
    if (file.size > 5000000) {
        alert('max upload size is 5mb')
    }

    // Also see .name, .type
});
function handleFiles(files) {
   

   
 
    getBase64(files); // prints the base64 string
    // Check for the various File API support.
    /* if (window.FileReader) {
        // FileReader are supported.
        getAsText(files[0]);
    } else {
        alert('FileReader are not supported in this browser.');
    }  */
}
function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        console.log(reader.result);
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

/*function getAsText(fileToRead) {
    var reader = new FileReader();
    // Read file into memory as UTF-8      
    reader.readAsText(fileToRead);
    // Handle errors load
    reader.onload = loadHandler;
    reader.onerror = errorHandler;
}

function loadHandler(event) {
    var csv = event.target.result;
    processData(csv);
    setCookie('csv', csv, 10000);
}

function processData(csv) {
    console.log(csv);

    var allTextLines = csv.split(/\r\n|\n/);
    console.log(allTextLines);
    var lines = [];
    for (var i = 0; i < allTextLines.length; i++) {
        var data = allTextLines[i].split(']');
        var tarr = [];
        for (var j = 0; j < data.length; j++) {
            tarr.push(data[j]);
        }
        lines.push(tarr);
    }

    console.log(lines);
    setCookie('lines', lines, 10000);
}

function errorHandler(evt) {
    if (evt.target.error.name == "NotReadableError") {
        alert("Canno't read file !");
    }
}  */
 



function customersalesforce() {
    var localUrl = document.querySelector('.progress__labels li:last-child').dataset.url;
    var order_id = getCookie('customer_order_id');

    var ContactPerson = getCookie('CustomerContactPerson');
    var res = ContactPerson.split(" ");
    var ConPersonFirstName = res[0];   
    var ConPersonLastName = res[1];

    var data = {

        'grant_type': 'password', 'client_id': '', 'client_secret': '', 'username': '', 'password': ''
    };
    $.ajax({
        type: "POST",
        url: '/Umbraco/Api/CustomerSalesforce/CreateCustomerAccessToken',
        // urlhttps://test.salesforce.com/services/oauth2/token,
        data: data,
        success: function (response) {
            var RequestId = getCookie('customer_order_id');
            var CustomerOrganizationName = getCookie('CustomerCompanyName');
            var CustomerOrganizationNumber = getCookie('CVR');
            var ContactPersonFirstName = ConPersonFirstName;
            var ContactPersonLastName = ConPersonLastName ;
            var ContactPersonEmail = getCookie('CustomerEmail');
            var CustomerStreetName = getCookie('CustomerAddress');
            var CustomerPostalCode = getCookie('CustomerPostalCode');
            var CustomerCity = getCookie('CustomerCity');
            var ContactPersonMobilePhone = getCookie('CustomerPhone');
            var SelfServicePortalName = getCookie('');
            var SelfServicePortalEmail = getCookie('');
            var NumberOfCards = getCookie('cardAmount');
            var CardType = getCookie('cardType');

            var cardvalue = JSON.parse(getCookie("cardvalue"));
            console.log(cardvalue);

            var Card1SubCardType = "card type 1 :" + cardvalue[0].cardType;
            var Card1TextOnCard = "short text 1 :" +  cardvalue[0].eurocardtext;
            var Card1KMRegistering = cardvalue[0].meter;
            var Card1DriverSolution =  cardvalue[0].driver;
            if (cardvalue[1]) {
                var Card2SubCardType = "card type 2 :" + cardvalue[1].cardType;
                var Card2TextOnCard = "short text 2 :" + cardvalue[1].eurocardtext;
                var Card2KMRegistering =  cardvalue[1].meter;
                var Card2DriverSolution = cardvalue[1].driver;
            }
            if (cardvalue[2]) {
                var Card3SubCardType = "card type 3 :" + cardvalue[2].cardType;;
                var Card3TextOnCard = "short text 3 :" + cardvalue[2].eurocardtext;
                var Card3KMRegistering =  cardvalue[2].meter;
                var Card3DriverSolution =  cardvalue[2].driver;
            }
            if (cardvalue[3]) {
                var Card4SubCardType = "card type 4 :" + cardvalue[3].cardType;;
                var Card4TextOnCard = "short text 4 :" + cardvalue[3].eurocardtext;
                var Card4KMRegistering =  cardvalue[3].meter;
                var Card4DriverSolution =  cardvalue[3].driver;
            }
            if (cardvalue[4]) {
                var Card5SubCardType = "card type 5 :" +  cardvalue[4].cardType;;
                var Card5TextOnCard = "short text 5 :" + cardvalue[4].eurocardtext;
                var Card5KMRegistering = cardvalue[4].meter;  
                var Card5DriverSolution =  cardvalue[4].driver;
            }

            var DesiredCreditLimit = getCookie('creditValue');
            var PurchaseProfile = getCookie('creditGroup');
            var invoicingType = getCookie('paymentMethod');
            var InvoiceEmailAddress = getCookie('emailInvoiceEmail');
            var InvoiceEmailContactName = getCookie('emailInvoiceContact');
            var PaymentServiceRegNo = getCookie('reg');
            var PaymentServiceAccountNo = getCookie('account');
            var EANNumber = getCookie('ean');
            var TermsAndConditions = getCookie('customerterms');
            var otherComments = getCookie('customercomments');
            window.access_token = response.access_token;
            
            var data = {
                AccessToken: response.access_token,
                Cases: [{
                    'RequestId': RequestId,
                    'CustomerOrganizationName': CustomerOrganizationName,
                    'CustomerOrganizationNumber': CustomerOrganizationNumber,
                    'ContactPersonFirstName': ContactPersonFirstName,
                    'ContactPersonLastName': ContactPersonLastName,
                    'ContactPersonEmail': ContactPersonEmail,
                    'CustomerStreetName': CustomerStreetName,
                    'CustomerPostalCode': CustomerPostalCode,
                    'CustomerCity': CustomerCity,
                    'SelfServicePortalName': SelfServicePortalName,
                    'SelfServicePortalEmail': SelfServicePortalEmail,
                    'ContactPersonMobilePhone': ContactPersonMobilePhone ,   
                    'NumberOfCards': NumberOfCards,
                    'CardType': CardType,
                    'Card1SubCardType': Card1SubCardType,
                    'Card1TextOnCard': Card1TextOnCard,
                    'Card1KMRegistering': Card1KMRegistering,
                    'Card2SubCardType': Card2SubCardType,
                    'Card2TextOnCard': Card2TextOnCard,
                    'Card2KMRegistering': Card2KMRegistering,
                    'Card3SubCardType': Card3SubCardType,
                    'Card3TextOnCard': Card3TextOnCard,
                    'Card3KMRegistering': Card3KMRegistering,
                    'Card4SubCardType': Card4SubCardType,
                    'Card4TextOnCard': Card4TextOnCard,
                    'Card4KMRegistering': Card4KMRegistering,
                    'Card5SubCardType': Card5SubCardType,
                    'Card5TextOnCard': Card5TextOnCard,
                    'Card5KMRegistering': Card5KMRegistering,
                    'DesiredCreditLimit': DesiredCreditLimit,
                    'PurchaseProfile': PurchaseProfile,
                    'invoicingType': invoicingType,
                    'InvoiceEmailAddress': InvoiceEmailAddress,
                    'InvoiceEmailContactName': InvoiceEmailContactName,
                    'PaymentServiceRegNo': PaymentServiceRegNo,
                    'PaymentServiceAccountNo': PaymentServiceAccountNo,
                    'EANNumber': EANNumber,
                    'TermsAndConditions': TermsAndConditions ,
                    'otherComments': otherComments
                }],
            };
            $.ajax({
               
                type: "POST",
                url: '/Umbraco/Api/CustomerSalesforce/CustomerCaseManager',
                data: data,
                success: function (response) {
                    if (NumberOfCards > 5) {
                        var data = {

                            'access_token': window.access_token,
                            'GormRequestID__c': RequestId,
                        }

                        $.ajax({
                            type: "POST",
                            url: '/Umbraco/Api/CustomerSalesforce/Uploadattachment',
                            data: data,

                            success: function (csvResponse) {
                                console.log(csvResponse);
                            }
                        })
                            }
                   
                        var data = JSON.parse(response);
                        var salesforceId = data[0].salesforceId;
                        setCookie('salesforceId', salesforceId, 10000);
                        console.log(response);
                        //var newwindow = window.open(localUrl + "?f=1", "_self");
                   
                },


            });
            

        },
    });
}
