function salesforce() {
    var localUrl = document.querySelector('.progress__labels li:last-child').dataset.url;
    var order_id = getCookie('order_id');
    var ContactPersonFirstName = getCookie('ContactPersonFirstName');
    var ContactPersonLastName = getCookie('ContactPersonLastName');
    var QuickpayId = getCookie('paymentid');
   
       var data = { 
       
           'grant_type': 'password', 'client_id': '', 'client_secret': '', 'username': '', 'password': ''
    }; 
       $.ajax({
           type: "POST",
           url: '/Umbraco/Api/Salesforce/CreateAccessToken',
           // urlhttps://test.salesforce.com/services/oauth2/token,
           data: data,
           success: function (response) {
               var Quickpayid = QuickpayId;
               var RequestId = getCookie('order_id');
               var ContactPersonFirstName = getCookie('ContactPersonFirstName');
               var ContactPersonLastName = getCookie('ContactPersonLastName');
               var CustomerPersonalIDNumber = getCookie('CustomerPersonalIDNumber');
               var ContactPersonMobilePhone = getCookie('ContactPersonMobilePhone');
               var ContactPersonEmail = getCookie('ContactPersonEmail');
               var Product = getCookie('oilTypeName') || 'Basis';     
               var QuantityLitres = getCookie('literAmount');
               var invoicePrice = getCookie('invoicePrice');
               var DeliveryDate = getCookie('DeliveryDate');
               var DeliveryContactPerson = getCookie('DeliveryContactPerson');
               var DeliveryContactFirstName = getCookie('DeliveryContactFirstName');
               var DeliveryContactLastName = getCookie('DeliveryContactLastName');
               var DeliveryContactPhone = getCookie('DeliveryContactPhone');
               var OtherComments = getCookie('comments');
               var TankSize = getCookie('tankSize');
               var PersonalAddressStreet = getCookie('PersonalAddressStreet');
               var PersonalAddressCity = getCookie('PersonalAddressCity');
               var PersonalAddressPostCode = getCookie('PersonalAddressPostCode');
               var DeliveryAddressStreet = getCookie('DeliveryAddressStreet');
               var DeliveryAddressCity = getCookie('DeliveryAddressCity');
               var DeliveryAddressPostCode = getCookie('DeliveryAddressPostCode');
               var FillUpTank = getCookie('toppedUp');   
               var TankPlace = getCookie('tankPlacement');     
               var PaymentTerms = getCookie('paymentMethod');
               var PriceDKK = getCookie('subTotalPrice');
               var DiscountDKK = getCookie('totalDiscount');
               var FinalPriceDKK = getCookie('totalPrice');
               var InvoiceEmail = getCookie('invoiceEmail');
               var InvoicePost = getCookie('invoicePost');
               var RenewalEmailNotification = getCookie('email');
               var AutomaticDelivery = getCookie('automatic');
               var SquareMeters = getCookie('squareMeters');
               var SquareMetersEmailNotification = getCookie('SquareMetersEmailNotification');
               var TermsAndConditions = getCookie('terms');
               var InvoicePostDetails = decodeURI(getCookie('invoiceSplit'));
               if (PaymentTerms == "invoice") {
                   OtherComments = OtherComments;
               }
               else {
                   OtherComments = OtherComments + " " + "QUICK PAY ID :" + Quickpayid;
               }
                  
               var data = {   
                   AccessToken : response.access_token,
                   Cases: [{   
                       'RequestId': RequestId,                     
                       'CustomerPersonalIDNumber': CustomerPersonalIDNumber,
                       'ContactPersonLastName': ContactPersonLastName,
                       'ContactPersonFirstName': ContactPersonFirstName,
                       'ContactPersonMobilePhone': ContactPersonMobilePhone ,
                       'InvoiceEmail': InvoiceEmail,
                       'InvoicePost': InvoicePost,
                       'InvoicePrice': invoicePrice ,
                       'RenewalEmailNotification': RenewalEmailNotification,
                       'AutomaticDelivery': AutomaticDelivery,
                       'SquareMeters': SquareMeters,
                       'SquareMetersEmailNotification': SquareMetersEmailNotification,
                       'ContactPersonEmail': ContactPersonEmail,
                       'Product': Product,
                       'QuantityLitres': QuantityLitres,
                       'DeliveryDate': DeliveryDate,
                       'DeliveryContactPerson': DeliveryContactFirstName + ' ' + DeliveryContactLastName,
                       'DeliveryContactPhone': DeliveryContactPhone,                       
                       'OtherComments': OtherComments ,
                       'TankSize': TankSize,
                       'PersonalAddressStreet': PersonalAddressStreet ,
                       'PersonalAddressCity': PersonalAddressCity,
                       'PersonalAddressPostCode': PersonalAddressPostCode,
                       'DeliveryAddressPostCode': DeliveryAddressPostCode,
                       'DeliveryAddressStreet': DeliveryAddressStreet ,
                       'DeliveryAddressCity': DeliveryAddressCity,
                       'FillUpTank': FillUpTank,
                       'TankPlace': TankPlace,
                       'PaymentTerms': PaymentTerms, 
                       'PriceDKK': PriceDKK,
                       'DiscountDKK': DiscountDKK,
                       'FinalPriceDKK': FinalPriceDKK,
                       'TermsAndConditions': TermsAndConditions,
                       'InvoicePostDetails': InvoicePostDetails
                   }]  ,
               };    
               $.ajax({
                   type: "POST",
                   url: '/Umbraco/Api/Salesforce/CaseManager',
                  data: data,
                  success: function (response) {
                      
                      var data = JSON.parse(response);
                      if (data.error != null) {
                          alert("error!")
                        
                      }
                      var salesforceId = data[0].salesforceId;                    
                      setCookie('salesforceId', salesforceId , 10000);
                      console.log(response);                                                        
                      var newwindow = window.open(localUrl+"?f=1", "_self");
                   },


               });

           },
       });
}
function personalinfo() {



}
