$('input[type="text"], input[type="tel"], textarea').focusout(function () {
    nicelabelCheck($(this));
});

$('select').focusout(function () {
    nicelabelCheckSelect($(this));
});

function nicelabelCheck(input) {
    var text_val = input.val();

    if (text_val === "") {
        input.removeClass('has-value');
    } else {
        input.addClass('has-value');
    }
}

function nicelabelCheckSelect(input) {
    var text_val = input.val();
    console.log(text_val);

    if (text_val === "") {
        input.removeClass('has-value');
    } else {
        input.addClass('has-value');
    }
}


if ($('input').length) {
    $('input').each(function () {
        nicelabelCheck($(this));
    });
}

if ($('textarea').length) {
    $('textarea').each(function () {
        nicelabelCheck($(this));
    });
}

if ($('select').length) {
    $('select').each(function () {
        nicelabelCheckSelect($(this));
    });
}