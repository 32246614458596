window.addEventListener("load", function(){
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#313131",
                "text": "#fff"
            },
            "button": {
                "background": "#f99b39",
                "text": "#fff"
            }
        },
        "position": "bottom-right",
        // "static": true,
        "content": {
            "message": "Dette website bruger cookies for at sikre dig den bedste oplevelse.",
            "dismiss": "Jeg accepterer",
            "link": "Læs mere",
            "href": "https://www.circlek.dk/dk_DK/pg1332347186399/Cookies.html"
        }
    });
});