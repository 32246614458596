if ($('.customerStep1').length) {
    $("#CVR_for_test").inputmask("99999999");

    $(".cvr_form").validate({
        rules: {
            CVR_for_test: {
                required: true,
                digits: true,
                minlength: 8,
                maxlength: 8
            },
        },
        messages: {
            CVR_for_test: {
                required: "CVR-nummer skal udfyldes.",
                digits: "CVR-nummeret må kun indeholde tal.",
                minlength: "CVR-nummeret skal være {0} cifre.",
                maxlength: "CVR-nummeret skal være {0} cifre."
            }
        },
        submitHandler: function (form) {
            cvrapi(form.CVR_for_test.value, 'dk');
        }
    });
}

function cvrapi(vat, country) {
    jQuery.ajax
        ({
            type: "GET",
            dataType: "jsonp",
            url: "//cvrapi.dk/api?search=" + vat + "&country=" + country,
            success: function (b) {
            
                $("#CustomerCompanyName").val(b.name);
                $("#CustomerAddress").val(b.address);
                $("#CustomerCity").val(b.city);
                $("#CustomerPostalCode").val(b.zipcode);
                $('#CVR').val(vat);

                $('input').each(function () {
                    nicelabelCheck($(this));
                });
            },  
            error: function (response) {
                alert("Please enter valid CVR!");
            }

            
        });
    }