if ($('.customerStep1').length) {
    // Proper email validation rule added.
    $.validator.addMethod("email", function (e, t) {
        return this.optional(t) || /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(e)
    }, "Please type a proper email address.");

    // Get cookies.
    var CVR = getCookie('CVR');
    var CustomerCompanyName = getCookie('CustomerCompanyName');
    var CustomerContactPerson = getCookie('CustomerContactPerson');
    var CustomerAddress = getCookie('CustomerAddress');
    var CustomerPostalCode = getCookie('CustomerPostalCode');
    var CustomerCity = getCookie('CustomerCity');
    var CustomerEmail = getCookie('CustomerEmail');
    var CustomerPhone = getCookie('CustomerPhone');

    // Populate fields if cookies are set.
    $('#CVR').val(CVR);
    $('#CustomerCompanyName').val(CustomerCompanyName);
    $('#CustomerContactPerson').val(CustomerContactPerson);
    $('#CustomerAddress').val(CustomerAddress);
    $('#CustomerPostalCode').val(CustomerPostalCode);
    $('#CustomerCity').val(CustomerCity);
    $('#CustomerEmail').val(CustomerEmail);
    $('#CustomerPhone').val(CustomerPhone);

    $('input').each(function () {
        nicelabelCheck($(this));
    });


    /* Create customerorderId */
    function makeid() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 6; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }
    var customer_order_id = makeid();
    setCookie('customer_order_id', customer_order_id, 10000); 
    // Validate form and set cookies.
    $('#form-customer-info').validate({
        rules: {
            CustomerContactPerson: {
                required: true,
                minlength: 2
            },
            CustomerPhone: {
                required: true,
                digits: true,
                minlength: 8,
                maxlength: 8
            },
            CustomerEmail: {
                required: true,
                email: true
            },
        },
        messages: {
            CustomerContactPerson: {
                required: "Du skal angive en kontaktperson."
            },
            CustomerPhone: {
                required: "Telefonnummer skal udfyldes.",
                minlength: $.validator.format("Telefonnummeret skal være på minimum {0} cifre."),
                digits: "Telefonnummeret må kun indeholde tal."
            },
            CustomerEmail: {
                required: "Email skal udfyldes.",
                email: "Udfyld en gyldig email-adresse."
            },

        },
        submitHandler: function (form) {
            setCookie('CVR', form.CVR.value, 10000);
            setCookie('CustomerCompanyName', form.CustomerCompanyName.value, 10000);
            setCookie('CustomerContactPerson', form.CustomerContactPerson.value, 10000);
            setCookie('CustomerAddress', form.CustomerAddress.value, 10000);
            setCookie('CustomerPostalCode', form.CustomerPostalCode.value, 10000);
            setCookie('CustomerCity', form.CustomerCity.value, 10000);
            setCookie('CustomerEmail', form.CustomerEmail.value, 10000);
            setCookie('CustomerPhone', form.CustomerPhone.value, 10000);
            form.submit();
        }
    });
}