function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=/";

}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return decodeURIComponent(c.substring(name.length, c.length));
        }
    }
    return "";
}
 

 

function setCookiePrototype(cname, cvalue, exdays) {
    var d = new Date();
    var stringObject = getObject();
    var JsonObject;
    if (stringObject == "") {
        JsonObject = {};
    } else {
        JsonObject = JSON.parse(stringObject);
    }
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    JsonObject[cname] = cvalue;
    document.cookie = "cookie=" + JSON.stringify(JsonObject) + ";" + expires + ";path=/";
    //console.log(cvalue);
}

function getObject() {
    var name = "cookie=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function getCookiePrototype(name) {
    var stringObject = getObject();
    if (stringObject == "") return "";
    var jsObject = JSON.parse(stringObject);
    //console.log(jsObject);
    var result = jsObject[name];
    if (result == null) {
        return "";
    }
    return result;
}

