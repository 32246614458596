if ($('.cart').length) {
    // Cart drop down functionality
    $('.cart__dropdown').click(function() {
        $(this).parent().toggleClass('-active');
    });

    // Update cart data if the slider is not present. If slider is present, slider.js will take over.
    if (!$('.slider').length) {
        // Set numeral to Danish price structure. Documentation for Numeral: http://numeraljs.com/
        numeral.locale('da-dk');
        updatePrices(getCookie('literAmount'), getPricePer1kLiter(), getCookie('oilTypeName') || 'Basis');
    }
}
 