if ($('.customerStep3').length) {
 
    // Get cookies
    var creditLimit = getCookie('creditLimit');
    var creditGroup = getCookie('creditGroup');
    var cardType = getCookie('cardType');

    if (creditLimit.length > 0) {
        $('input[value="' + creditLimit + '"]').prop('checked', true);
    }

    if (creditGroup.length > 0) {
        $('input[value="' + creditGroup + '"]').prop('checked', true);
    }

    // Set cookies
    $('input[name="billingMethod"]').change(function () {
        setCookie('creditLimit', $(this).val(), 10000);
    });

    $('input[name="creditGroup"]').change(function () {
        setCookie('creditGroup', $(this).val(), 10000);
    });

    $.validator.setDefaults({
        ignore: [] // DON'T IGNORE PLUGIN HIDDEN SELECTS, CHECKBOXES AND RADIOS!!!
    });

    $('.form-billing-method').validate({
        errorPlacement: function (error, element) {
            $('p.' + error.attr('id')).html(error).show();
        },
        rules: {
            billingMethod: {
                required: true
            },
            creditGroup: {
                required: true
            }
        },
        messages: {
            billingMethod: {
                required: 'Du skal huske at vælge en kreditgrænse.'
            },
            creditGroup: {
                required: 'Du skal huske at vælge om kredtigrænsen skal være lempelig eller streng.'
            }
        },
        submitHandler: function (form) {
            form.submit();
        }
    });
}