$(document).ready(function () {
    if ($('.master-card-sign-up').length) {
        var trackPosition = 0;
        var steps = $('.mastercard__step')
        var slidesAmount = steps.length;
        var stepsProgress = 100 / slidesAmount;

        $('#mastercardResend').on("click", function () {
            $('#phonecode').val('');
            $('#phonecode').focus();
            $('.mastercard__phonecode-placeholder').html('');
            $('.mastercard__phonecode-placeholder').removeClass('-active');
            $('.mastercard__column:first-of-type .mastercard__phonecode-placeholder').addClass('-active');
        });

        steps.each(function () {
            $(this).data("height", Math.round($(this).height()));
        });

        $('.mastercard__back').click(function () {
            $('#mastercard__to-step2').removeAttr('disabled');
            trackPosition += stepsProgress;
            $('.mastercard__track').css('transform', 'translateX(' + trackPosition + '%)');
        });

        // Proper email validation rule added.
        $.validator.addMethod("email", function (e, t) {
            return this.optional(t) || /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(e)
        }, "Please type a proper email address.");

        // Validation
        $('#mastercard_form').validate({
            rules: {
                mastercardFirstName: {
                    required: true,
                    minlength: 2
                },
                mastercardLastName: {
                    required: true,
                    minlength: 2
                },
                mastercardPhone: {
                    required: true,
                    digits: true,
                    minlength: 8,
                    maxlength: 8
                },
                mastercardEmail: {
                    required: true,
                    email: true
                },
                mastercardAlreadyCustomerCheck: {
                    required: true
                }
                /*mastercardPermission: {
                    required: true
                },*/
            },
            messages: {
                mastercardFirstName: {
                    required: "Du skal skrive dit fornavn"
                },
                mastercardLastName: {
                    required: "Du skal skrive dit efternavn"
                },
                mastercardPhone: {
                    required: "Telefonnummer skal udfyldes.",
                    minlength: $.validator.format("Telefonnummeret skal være på minimum {0} cifre."),
                    digits: "Telefonnummeret må kun indeholde tal."
                },
                mastercardEmail: {
                    required: "Email skal udfyldes.",
                    email: "Udfyld en gyldig email-adresse."
                },
                mastercardAlreadyCustomerCheck: {
                    required:"Du skal vælge et felt."
                }
                /*mastercardPermission: {
                    required: "Du skal give samtykke til at vi må kontakte dig."
                },*/

            },
            errorPlacement: function (error, element) {
                if (element.attr("name") == "mastercardAlreadyCustomerCheck") {
                    var target = $(element).parent().parent().parent();
                    error.insertAfter(target);
                } else {
                    error.insertAfter(element);

                }
            },
            highlight: function (element, errorClass, validClass) {
                if ($(element).attr("id") == "mastercardPermission") {
                    $(element).addClass(errorClass).removeClass(validClass);
                    $(element).parents('#mastercard__permission--error').addClass(errorClass).removeClass(validClass);
                } else if ($(element).attr("name") == "mastercardAlreadyCustomerCheck") {
                    $(element).parents('#mastercard__existing-customer-check').addClass(errorClass).removeClass(validClass);
                } else {
                    $(element).addClass(errorClass).removeClass(validClass);
                }
                
            },
            unhighlight: function (element, errorClass, validClass) {
                if ($(element).attr("id") == "mastercardPermission") {
                    $(element).removeClass(errorClass).addClass(validClass);
                    $(element).parent().children('.error').removeClass(errorClass).addClass(validClass);
                    $(element).parents('#mastercard__permission--error').removeClass(errorClass).addClass(validClass);
                } else if ($(element).attr("name") == "mastercardAlreadyCustomerCheck") {
                    $(element).parents('#mastercard__existing-customer-check').removeClass(errorClass).addClass(validClass);
                } else {
                    $(element).removeClass(errorClass).addClass(validClass);
                }
            },
            submitHandler: function (form, event) {
                $('#mastercard__to-step2').attr('disabled', 'disabled');

                event.preventDefault();
                var $form = $(form);
                var $form2 = $('#mastercard_form2');
                var $resendForm = $('#mastercard_form_resend');
                var url = $form.attr('action');
                $('.mastercard__error-msg').addClass('-inactive');

                $form.serialize()

                $.ajax({
                    url: url,
                    type: 'POST',
                    data: $form.serialize()
                })
                .done(function (data) {
                    $form2.find('#mastercardFirstName').val($form.find('#mastercardFirstName').val());
                    $form2.find('#mastercardLastName').val($form.find('#mastercardLastName').val());
                    $form2.find('#mastercardEmail').val($form.find('#mastercardEmail').val());
                    $form2.find('#mastercardPhone').val($form.find('#mastercardPhone').val());

                    if ($form.find('#mastercardPermission').is(":checked")) {
                        $form2.find('#mastercardPermission').val($form.find('#mastercardPermission').val());
                    } else {
                        $form2.find('#mastercardPermission').val(0);
                    }
                    if ($form.find('#mastercardAlreadyCustomerCheckYes').length > 0) {
                        if ($form.find('#mastercardAlreadyCustomerCheckYes').is(":checked")) {
                            $form2.find('#mastercardAlreadyCustomerCheckYes').val($form.find('#mastercardAlreadyCustomerCheckYes').val());
                        } else {
                            $form2.find('#mastercardAlreadyCustomerCheckYes').val(0);
                        }
                    } else {
                        $form2.find('#mastercardAlreadyCustomerCheckYes').val(0);
                    }
                    if ($form.find('#mastercardAlreadyCustomerCheckNo').length > 0) {
                        if ($form.find('#mastercardAlreadyCustomerCheckNo').is(":checked")) {
                            $form2.find('#mastercardAlreadyCustomerCheckNo').val($form.find('#mastercardAlreadyCustomerCheckNo').val());
                        } else {
                            $form2.find('#mastercardAlreadyCustomerCheckNo').val(0);
                        }
                    } else {
                        $form2.find('#mastercardAlreadyCustomerCheckNo').val(0);
                    }


                    var phonetext = $('.dynamic_phonenumber').text();
                    var phonetext2 = $('.dynamic_phonenumber2').text();
                    var phonenumber = $form.find('#mastercardPhone').val();
                    var censorednumber = phonenumber.substr(0, 2) + "****" + phonenumber.substr(6, 2);
                    phonetext = phonetext.replace('[mobilenumber]', censorednumber);
                    phonetext2 = phonetext2.replace('[mobilenumber]', censorednumber);
                    $('.dynamic_phonenumber').text(phonetext);
                    $('.dynamic_phonenumber2').text(phonetext2);
                    $resendForm.find('#mastercardPhone').val($form.find('#mastercardPhone').val());

                    trackPosition -= stepsProgress;
                    $('.mastercard__track').css('transform', 'translateX(' + trackPosition + '%)');
                    $('html, body').animate({
                        scrollTop: $('.mastercard__step2').offset().top
                    }, 300);
                    setTimeout(function () {
                        $('#phonecode').focus();
                    }, 450); 

                    // Get current url and check if we have other parameters in the url
                    var pathname = window.location.href,
                        url = (pathname.indexOf("?") >= 0) ? pathname + '&step=2' : pathname + '?step=2';

                    window.history.pushState(data, "Step 2", url);
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    $('#mastercard__to-step2').removeAttr('disabled');
                    var response = jQuery.parseJSON(jqXHR.responseText);
                    $('.mastercard__error-msg').removeClass('-inactive');
                });
            }
        });

        $('.mastercard__existing-customer-checkbox').click(function () {
            $('.mastercard__existing-customer-checkbox').not(this).prop('checked', false);
        });
        $('#mastercard_form').on('blur keyup change', 'input', function (event) {
            var valid = true;

            var elements = $('#mastercard_form input[type="text"]');
            var checkbox = $('#mastercard_form input[type="checkbox"]:checked');

            for (var i = 0; i < elements.length; i++) {
                if (!elements[i].classList.contains('valid')) {
                    valid = false;
                }
            }

            /* if (checkbox.length == 0) {
                valid = false;
            } */

            if (valid) {
                $('#mastercard__to-step2').parent().removeClass('-inactive');
            } else {
                $('#mastercard__to-step2').parent().addClass('-inactive');
            }
        });

        // phone code
        $('#phonecode').on('input', function () {
            var value = $(this).val();
            var length = value.length;

            if (!$.isNumeric(value) && length > 0) {
                $('.mastercard__wrongcode-msg').removeClass('-inactive');
            } else {
                $('.mastercard__wrongcode-msg').addClass('-inactive');
            }

            for (i = 0; i < 4; i++) {
                var output = "";
                if (value[i] != undefined) {
                    output = value[i];
                }

                $('.mastercard__phonecode-placeholder:eq(' + i + ')').text(output);
                $('.mastercard__phonecode-placeholder:eq(' + length + ')').addClass('-active');
                $('.mastercard__phonecode-placeholder:eq(' + (length - 1) + '), .mastercard__phonecode-placeholder:eq(' + (length + 1) + ')').removeClass('-active');
            }
        });

        $('#mastercard_form2').validate({
            rules: {
                phonecode: {
                    required: true,
                    digits: true,
                    minlength: 4,
                    maxlength: 4
                },
            },
            highlight: function (element, errorClass, validClass) {
                //$(element).parent().parent().addClass(errorClass).removeClass(validClass);
                $('.mastercard__wrongcode-msg').removeClass('-inactive');
            },
            unhighlight: function (element, errorClass, validClass) {
                //$(element).parent().parent().removeClass(errorClass).addClass(validClass);
                $('.mastercard__wrongcode-msg').addClass('-inactive');
            },
            submitHandler: function (form, event) {

                // Do ajax thing here
                event.preventDefault();
                var $form = $(form);
                var url = $form.attr('action');

                // Hide possible messages
                $('.mastercard__error-msg').addClass('-inactive');
                $('.mastercard__wrongcode-msg').addClass('-inactive');
                $('.mastercard__resend-succes').addClass('-inactive');
                $('.mastercard__verified-msg').addClass('-inactive');

                $.ajax({
                    url: url,
                    type: 'POST',
                    data: $form.serialize()
                })
                .done(function (data) {

                    if (data.RedirectUrl) {
                        $("#mastercardStep3Button").attr("href", data.RedirectUrl)
                        $('.mastercard__verified-msg').removeClass('-inactive');
                    }

                    trackPosition -= stepsProgress;
                    $('.mastercard__track').css('transform', 'translateX(' + trackPosition + '%)');
                    $('html, body').animate({
                        scrollTop: $('.mastercard__step3').offset().top
                    }, 300);
                    $('.mastercard__circle').addClass('-active');

                    // Get current url and update the step value
                    var currentUrl = window.location.href;
                        url = new URL(currentUrl);

                    url.searchParams.set("step", "3");

                    var newUrl = url.href;

                    window.history.pushState(data, "Step 3", newUrl);
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    var response = jQuery.parseJSON(jqXHR.responseText);
                    $('.mastercard__wrongcode-msg').removeClass('-inactive');
                });
            }
        });

        $('#mastercard_form2').on('blur keyup change', 'input', function (event) {
            var valid = true;

            var element = $('#mastercard_form2 input[type="tel"]').val();

            if (element.length < 4) {
                valid = false;
            }

            if (valid) {
                $('#mastercard__to-step3').parent().removeClass('-inactive');
            } else {
                $('#mastercard__to-step3').parent().addClass('-inactive');
            }
        });

        $('#mastercard_form_resend').submit(function (e) {

            // Do ajax thing here
            e.preventDefault();
            var $form = $(this);
            var url = $form.attr('action');

            // Hide possible messages
            $('.mastercard__error-msg').addClass('-inactive');
            $('.mastercard__resend-succes').addClass('-inactive');

            $.ajax({
                url: url,
                type: 'POST',
                data: $form.serialize() + "&resend=true"
            })
            .done(function (data) {
                if (data.HtmlCode == "200" || data.HtmlCode == 200) {
                    $('.mastercard__resend-succes').removeClass('-inactive');
                }
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                var response = jQuery.parseJSON(jqXHR.responseText);
                $('.mastercard__error-msg').removeClass('-inactive');
            });
        });
    }

    $('section').on('click', 'a', function (event) {
        var hash = $(this).attr('href');
        var isAccordion = false;

        if ($(this).hasClass('accordion-title')) {
            isAccordion = true;
        }

        if (hash.indexOf("#faq") != -1 && !isAccordion) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $('.faq-section').offset().top
            }, 500, function () {
                $(".accordion").foundation('down', $(hash));
            });
        }
    });
});