document.addEventListener('DOMContentLoaded', function () {
    // Function to extract URL parameters
    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    // Populate referrer_source field with document.referrer
    var referrerSources = document.querySelectorAll('.referrer_source input[type="hidden"]');
    referrerSources.forEach(function (element) {
        element.value = document.referrer;
    });

    // Populate utm_source, utm_medium and utm_campaign fields with URL parameters
    var utmSources = document.querySelectorAll('.utm_source input[type="hidden"]');
    utmSources.forEach(function (element) {
        element.value = getParameterByName('utm_source') || '';
    }); 
     
    var utmMediums = document.querySelectorAll('.utm_medium input[type="hidden"]');
    utmMediums.forEach(function (element) {
        element.value = getParameterByName('utm_medium') || '';
    });

    var utmCampaigns = document.querySelectorAll('.utm_campaign input[type="hidden"]');
    utmCampaigns.forEach(function (element) {
        element.value = getParameterByName('utm_campaign') || '';
    });

    console.log("Form tracking active");
});
