if ($('.step3').length) {
    var onlineDiscount = getCookie("onlineDiscount");
    var productDiscount = getCookie("productDiscount");
    var cashDiscount = getCookie("cashDiscount");
    var campaignDiscount = getCookie("campaignDiscount");
    var literAmount = getCookie("literAmount");
    var oilTypeName = getCookie('oilTypeName') || 'Basis';
    var pricePer1kLiter = getPricePer1kLiter();
    var paymentMethod = getCookie('paymentMethod');
    //var invoiceEmail = getCookie('invoiceEmail');
    var invoiceSplit = decodeURI(getCookie('invoiceSplit'));
    var invoicePrice = getCookie('invoicePrice');
    
    if (paymentMethod.length > 0) {
        $('input[value="' + paymentMethod + '"]').prop('checked', true);

        var totalPrice = getCookie('totalPrice');
        $('.dynamic-TotalSplit3').text(numeral(totalPrice / 3).format("0,0.00"));
        $('.dynamic-TotalSplit6').text(numeral(totalPrice / 6).format("0,0.00"));
    }

    //if (invoiceEmail.length > 0) {
    //    $('input[value="' + invoiceEmail + '"]').prop('checked', true);
    //}

    if (getCookie('toppedUp') == "true") {
        $('#paymentMethodCreditCard').hide();
        $('#paymentMethodMobilpay').hide();
        
    }
    if (invoiceSplit.length > 0) {
        $('input[value="' + invoiceSplit + '"]').prop('checked', true);
    }

    $('input[name="paymentMethod"]').change(function () {
        setCookie('paymentMethod', $('input[name="paymentMethod"]:checked').val(), 10000);
        updatePrices(getCookie("literAmount"), getPricePer1kLiter(), getCookie("oilTypeName"));


        var totalPrice = getCookie('totalPrice');
        $('.dynamic-TotalSplit3').text(numeral(totalPrice / 3).format("0,0.00"));
        $('.dynamic-TotalSplit6').text(numeral(totalPrice / 6).format("0,0.00"));
    });

    $.validator.setDefaults({
        ignore: [] // DON'T IGNORE PLUGIN HIDDEN SELECTS, CHECKBOXES AND RADIOS!!!
    });

    $('.step3__form').validate({
        errorPlacement: function (error, element) {
            $('p.' + error.attr('id')).html(error).show();
        },
        rules: {
            paymentMethod: {
                required: true
            }
        },
        messages: {
            paymentMethod: {
                required: "Du skal vælge en betalingsmetode."
            }
        },
        submitHandler: function (form) {
            //var paymentMethod = form.paymentMethod.value;
            //var invoiceEmail = form.invoiceEmail.value;
            var invoiceSplit = form.invoiceSplit.value;
            var invoicePrice = $('#faktorprice').text();

            //setCookie('paymentMethod', paymentMethod, 10000);
            setCookie('invoiceEmail', true, 10000);

            //if ($('#invoice-email1').is(':checked')) {
            //    setCookie('invoiceEmail', true, 10000);
            //} else {
            //    setCookie('invoiceEmail', false, 10000);
            //}

            //if ($('#invoice-email2').is(':checked')) {
            //    setCookie('invoicePost', true, 10000);
            //} else {
            //    setCookie('invoicePost', false, 10000);
            //}
            //if (invoiceSplit.length >= 0 && invoiceSplit >= 0) {
            console.log(encodeURI(invoiceSplit));
            setCookie('invoiceSplit', encodeURI(invoiceSplit), 10000);
           // } else {
               // setCookie('invoiceSplit', false, 10000);
           // }
                setCookie('invoicePrice', invoicePrice, 10000);
            form.submit();
        }
    });
}
