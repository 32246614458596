// Proper email validation rule added.
$.validator.addMethod("email", function (value, element) {
    return this.optional(element) || /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value);
}, 'Please enter a valid email address.');

// CPR rule.
$.validator.addMethod("cpr", function (value, element) {
    return this.optional(element) || /^(?:(?:31(?:0[13578]|1[02])|(?:30|29)(?:0[13-9]|1[0-2])|(?:0[1-9]|1[0-9]|2[0-8])(?:0[1-9]|1[0-2]))[0-9]{2}\s?-?\s?[0-9]|290200\s?-?\s?[4-9]|2902(?:(?!00)[02468][048]|[13579][26])\s?-?\s?[0-3])[0-9]{3}|000000\s?-?\s?0000$/.test(value)
}, "Please type a proper email address.");


if ($('.step2').length) {
    var tankSize = getCookie('tankSize');
    var tankPlacement = getCookie('tankPlacement');
    var onlineDiscount = getCookie("onlineDiscount");
    var productDiscount = getCookie("productDiscount");
    var cashDiscount = getCookie("cashDiscount");
    var campaignDiscount = getCookie("campaignDiscount");
    var literAmount = getCookie("literAmount");
    var oilTypeName = getCookie('oilTypeName') || 'Basis';
    var pricePer1kLiter = getPricePer1kLiter();
    updatePrices(literAmount, pricePer1kLiter, oilTypeName);

    // Get cookies.
    var ContactPersonEmail = getCookie('ContactPersonEmail');
    var ContactPersonFirstName = getCookie('ContactPersonFirstName');
    var ContactPersonLastName = getCookie('ContactPersonLastName');
    var ContactPersonMobilePhone = getCookie('ContactPersonMobilePhone');
    var CustomerPersonalIDNumber = getCookie('CustomerPersonalIDNumber');
    var DeliveryAddressCity = getCookie('DeliveryAddressCity');
    var DeliveryAddressPostCode = getCookie('DeliveryAddressPostCode');
    var DeliveryAddressStreet = getCookie('DeliveryAddressStreet');
    var DeliveryContactFirstName = getCookie('DeliveryContactFirstName');
    var DeliveryContactLastName = getCookie('DeliveryContactLastName');
    var PersonalAddressCity = getCookie('PersonalAddressCity');
    var PersonalAddressPostCode = getCookie('PersonalAddressPostCode');
    var PersonalAddressStreet = getCookie('PersonalAddressStreet');
    var altDelivery = parseInt(getCookie('altDelivery'));

    // Populate fields if cookies are set.
    $('#ContactPersonEmail').val(ContactPersonEmail);
    $('#ContactPersonFirstName').val(ContactPersonFirstName);
    $('#ContactPersonLastName').val(ContactPersonLastName);
    $('#ContactPersonMobilePhone').val(ContactPersonMobilePhone);
    $('#CustomerPersonalIDNumber').val(CustomerPersonalIDNumber);
    $('#DeliveryAddressCity').val(DeliveryAddressCity);
    $('#DeliveryAddressPostCode').val(DeliveryAddressPostCode);
    $('#DeliveryAddressStreet').val(DeliveryAddressStreet);
    $('#DeliveryContactFirstName').val(DeliveryContactFirstName);
    $('#DeliveryContactLastName').val(DeliveryContactLastName);
    $('#PersonalAddressCity').val(PersonalAddressCity);
    $('#PersonalAddressPostCode').val(PersonalAddressPostCode);
    $('#PersonalAddressStreet').val(PersonalAddressStreet);

    if (tankSize.length > 0) {
        $('#tankSize').val(tankSize);
    }

    if (tankPlacement.length > 0) {
        $('#tankPlacement').val(tankPlacement);
    }

    if (altDelivery) {
        $('#altDelivery').prop('checked', true);
        $('#delivery').show();
    }

    // If checkbox checked then you see fields for different address.
    $("#altDelivery").on('change', function (event) {
        $("#delivery").slideToggle();
    });

    $('input').each(function () {
        nicelabelCheck($(this));
    });

    $("#CustomerPersonalIDNumber").inputmask("999999-9999");

    $("form button").click(function () {
        console.log(literAmount);
        console.log($('#tankSize').val());
    });

    // Validate form and set cookies.
    $('#form-customer-info').validate({
        rules: {
            tankSize: {
                required: true,
                digits: true,
                min: parseInt(literAmount),
            },
            CustomerPersonalIDNumber: {
                required: true,
                cpr: true,
                //digits: true,
                minlength: 11,
                maxlength: 11
            },
            ContactPersonFirstName: {
                required: true,
                minlength: 2
            },
            ContactPersonLastName: {
                required: true,
                minlength: 2
            },
            PersonalAddressStreet: {
                required: true,
                minlength: 2
            },
            PersonalAddressPostCode: {
                required: true,
                digits: true,
                minlength: 4,
                maxlength: 4
            },
            PersonalAddressCity: {
                required: true,
                minlength: 2
            },
            ContactPersonMobilePhone: {
                required: true,
                digits: true,
                minlength: 8
            },
            ContactPersonEmail: {
                required: true,
                email: true
            },
            DeliveryContactFirstName: {
                required: '#altDelivery:checked',
                minlength: 2
            },
            DeliveryContactLastName: {
                required: '#altDelivery:checked',
                minlength: 2
            },
            DeliveryAddressStreet: {
                required: '#altDelivery:checked',
                minlength: 2
            },
            DeliveryAddressPostCode: {
                required: '#altDelivery:checked',
                minlength: 4,
                maxlength: 4,
                digits: true
            },
            DeliveryAddressCity: {
                required: '#altDelivery:checked',
                minlength: 2
            }
        },
        messages: {
            tankSize: {
                required: "Dette felt skal udfyldes.",
                digits: "Feltet må kun indeholde tal.",
                min: $.validator.format("Tankstørrelsen skal minimum være på {0} liter.")
            },
            CustomerPersonalIDNumber: {
                required: "CPR-nummer skal udfyldes.",
                cpr: "CPR-nummeret må kun indeholde tal og skal være på 10 cifre.",
                minlength: $.validator.format("CPR-nummeret skal være på 10 cifre."),
                maxlength: $.validator.format("CPR-nummeret skal være på 10 cifre."),
                //digits: "CPR-nummeret må kun indeholde tal."
            },
            ContactPersonFirstName: "Fornavn skal udfyldes.",
            ContactPersonLastName: "Efternavn skal udfyldes.",
            PersonalAddressStreet: "Adresse skal udfyldes.",
            PersonalAddressPostCode: {
                required: "Postnummer skal udfyldes.",
                minlength: $.validator.format("Postnummeret skal være på {0} cifre."),
                maxlength: $.validator.format("Postnummeret skal være på {0} cifre."),
                digits: "Postnummeret må kun indeholde tal."
            },
            PersonalAddressCity: "By skal udfyldes.",
            ContactPersonMobilePhone: {
                required: "Telefonnummer skal udfyldes.",
                minlength: $.validator.format("Telefonnummeret skal være på minimum {0} cifre."),
                digits: "Telefonnummeret må kun indeholde tal."
            },
            ContactPersonEmail: {
                required: "Email skal udfyldes.",
                email: "Udfyld en gyldig email-adresse."
            },
            DeliveryContactFirstName: "Fornavn skal udfyldes.",
            DeliveryContactLastName: "Efternavn skal udfyldes.",
            DeliveryAddressStreet: "Adresse skal udfyldes.",
            DeliveryAddressPostCode: {
                required: "Postnummer skal udfyldes.",
                minlength: $.validator.format("Postnummeret skal være på {0} cifre."),
                maxlength: $.validator.format("Postnummeret skal være på {0} cifre."),
                digits: "Postnummeret må kun indeholde tal."
            },
            DeliveryAddressCity: "By skal udfyldes."

        },
        submitHandler: function (form) {
            // var form = document.getElementById('form-customer-info');
            var tankSize = form.tankSize.value;
            var tankPlacement = $('#tankPlacement').val();

            setCookie("tankSize", tankSize, 10000);
            if (tankPlacement.length > 0) {
                setCookie("tankPlacement", tankPlacement, 10000);
            }

            setCookie('CustomerPersonalIDNumber', form.CustomerPersonalIDNumber.value, 10000);
            setCookie('ContactPersonFirstName', form.ContactPersonFirstName.value, 10000);
            setCookie('ContactPersonLastName', form.ContactPersonLastName.value, 10000);
            setCookie('PersonalAddressStreet', form.PersonalAddressStreet.value, 10000);
            setCookie('PersonalAddressPostCode', form.PersonalAddressPostCode.value, 10000);
            setCookie('PersonalAddressCity', form.PersonalAddressCity.value, 10000);
            setCookie('ContactPersonMobilePhone', form.ContactPersonMobilePhone.value, 10000);
            setCookie('ContactPersonEmail', form.ContactPersonEmail.value, 10000);
            if ($('#altDelivery').is(':checked')) {
                setCookie('altDelivery', 1, 10000);
                setCookie('DeliveryContactFirstName', form.DeliveryContactFirstName.value, 10000);
                setCookie('DeliveryContactLastName', form.DeliveryContactLastName.value, 10000);
                setCookie('DeliveryAddressStreet', form.DeliveryAddressStreet.value, 10000);
                setCookie('DeliveryAddressPostCode', form.DeliveryAddressPostCode.value, 10000);
                setCookie('DeliveryAddressCity', form.DeliveryAddressCity.value, 10000);
            } else {
                setCookie('altDelivery', 0, 10000);
                setCookie('DeliveryContactFirstName', "", 0);
                setCookie('DeliveryContactLastName', "", 0);
                setCookie('DeliveryAddressStreet', "", 0);
                setCookie('DeliveryAddressPostCode', "", 0);
                setCookie('DeliveryAddressCity', "", 0);
            }
            form.submit();
        }
    });
}
