/**
 * A helpers function for getting the price per 1000 liters for the currently selected oil type
 *
 * @returns {Number} Price
 */
function getPricePer1kLiter() {
    var oilType = getCookie('oilTypeName') || 'Basis';
    var priceElement = document.querySelector('.prices div[data-name="' + oilType + '"]');

    if(priceElement) {
        return parseInt(priceElement.dataset.price) / 1000;
    }

    throw new Error('Current oil type could not be found');
}

/**
 * Getting values directly from the "settings" element
 * Usage: getSetting('cashDiscount')
 *
 * @param {String} key
 *
 * @returns {String} Value
 */
function getSetting(key) {
    var settings = document.querySelector('.settings');

    if (!settings) { return; }

    return settings.dataset[key];
}