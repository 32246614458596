if ($('.customerStep4').length) {
    // Get cookies
    var paymentMethod = getCookie('paymentMethod');

    // Populate data
    if (paymentMethod.length > 0) {
        $('input[value="' + paymentMethod + '"]').prop('checked', true);
    }
    $('input[type="text"]').each(function () {
        var variable = $(this).attr('name');
        $(this).val(getCookie(variable));
        nicelabelCheck($(this));
    });

    // Set cookies
    $('input[name="paymentMethod"]').change(function () {
        setCookie('paymentMethod', $(this).val(), 10000);
    });

    $.validator.setDefaults({
        ignore: [] // DON'T IGNORE PLUGIN HIDDEN SELECTS, CHECKBOXES AND RADIOS!!!
    });

    // Proper email validation rule added.
    $.validator.addMethod("properemail", function (value, element) {
        return this.optional(element) || /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value);
    }, 'Please enter a valid email address.');

    $('.step4__form').validate({
        errorPlacement: function (error, element) {
            $('p.' + error.attr('id')).html(error).show();
        },
        rules: {
            paymentMethod: {
                required: true
            },
            reg: {
                required: '#paymentMethod-3:checked',
                digits: true,
                minlength: 4,
                maxlength: 4,
            },
            account: {
                required: '#paymentMethod-3:checked',
                digits: true,
                minlength: 7,
                maxlength: 10,
            },
            ean: {
                required: '#paymentMethod-4:checked',
                digits: true,
                minlength: 5
            },
            emailInvoiceContact: {
                required: '#paymentMethod-5:checked',
                minlength: 2
            },
            emailInvoiceEmail: {
                required: '#paymentMethod-5:checked',
                properemail: true
            },
            compatible_ean: {
                digits: true,
                minlength: 5
            },
            compatible_emailInvoiceContact: {
                minlength: 2,
                skip_or_fill_minimum: [2, "#compatible_emailInvoiceContact, #compatible_emailInvoiceEmail"]
            },
            compatible_emailInvoiceEmail: {
                properemail: true,
                skip_or_fill_minimum: [2, "#compatible_emailInvoiceContact, #compatible_emailInvoiceEmail"]
            },
        },
        messages: {
            paymentMethod: {
                required: "De skal huske at vælge faktureringsmetode.",
            },
            reg: {
                required: "De skal udfylde reg. nr.",
                minlength: "Reg. nr. skal være på {0} cifre.",
                maxlength: "Reg. nr. skal være på {0} cifre.",
                digits: "Feltet må kun indeholde tal."
            },
            account: {
                required: "De skal udfylde kontonummer.",
                minlength: "Kontonummer må minimum være {0} cifre.",
                maxlength: "Kontonummer må maximum være {0} cifre.",
                digits: "Feltet må kun indeholde tal."
            },
            ean: {
                required: "De skal udfylde EAN nummer.",
                digits: "EAN nummeret må kun indeholde tal.",
                minlength: "De skal udfylde et korrekt EAN nummer."
            },
            emailInvoiceContact: {
                required: "De skal udfylde en kontaktperson.",
                minlength: "De skal udfylde en kontaktperson.",
            },
            emailInvoiceEmail: {
                required: "Email skal udfyldes.",
                properemail: "Udfyld en gyldig email-adresse."
            },  
            compatible_ean: {
                required: "De skal udfylde EAN nummer.",
                digits: "EAN nummeret må kun indeholde tal.",
                minlength: "De skal udfylde et korrekt EAN nummer."
            },
            compatible_emailInvoiceContact: {
                required: "De skal udfylde en kontaktperson.",
                minlength: "De skal udfylde en kontaktperson.",
                skip_or_fill_minimum: "De skal udfylde begge felter hvis de ønsker at modtage fakturaen via email."
            },
            compatible_emailInvoiceEmail: {
                required: "Email skal udfyldes.",
                properemail: "Udfyld en gyldig email-adresse.",
                skip_or_fill_minimum: "De skal udfylde begge felter hvis de ønsker at modtage fakturaen via email."
            },
        },
        submitHandler: function (form) {
            paymentMethod = form.paymentMethod.value;

            if (paymentMethod == "Betalings-service") {
                setCookie('reg', form.reg.value, 10000);
                setCookie('account', form.account.value, 10000);

                if (form.compatible_ean.value.length > 0) {
                    setCookie('compatible_ean', form.compatible_ean.value, 10000);
                }

                if (form.compatible_emailInvoiceContact.value.length > 0) {
                    setCookie('compatible_emailInvoiceContact', form.compatible_emailInvoiceContact.value, 10000);
                }

                if (form.compatible_emailInvoiceEmail.value.length > 0) {
                    setCookie('compatible_emailInvoiceEmail', form.compatible_emailInvoiceEmail.value, 10000);
                }
            } else {
                setCookie('reg', "", 10000);
                setCookie('account', "", 10000);
            }

            if (paymentMethod == "EAN Fakturering") {
                setCookie('ean', form.ean.value, 10000);
            } else {
                setCookie('ean', "", 10000);
            }

            if (paymentMethod == "E-mail fakturering") {
                setCookie('emailInvoiceContact', form.emailInvoiceContact.value, 10000);
                setCookie('emailInvoiceEmail', form.emailInvoiceEmail.value, 10000);
            } else {
                setCookie('emailInvoiceContact', "", 10000);
                setCookie('emailInvoiceEmail', "", 10000);
            }

            form.submit();
        }
    });


}