if ($('.customerStep5').length) {
    var cardAmount = getCookie('cardAmount');
    var cardType = getCookie('cardType');
    var cards = $.parseJSON(getCookie('cookie'));
    console.log(cards);

    if (cardAmount > 5) {
        cardAmount = '5+';
        $('.dynamic-cardTypes').append('Se CSV-fil');
    } else {
        var html = '';
        for (i = 0; i < cardAmount; i++) {
            if (i > 0) {
                html += ', ';
            }
            html += cards[cardType + '-VariantPickerDropdown-' + (i + 1)];
        }
        $('.dynamic-cardTypes').append(html);
    }

    if ($('#customerterms').is(':checked')) {
        setCookie('customerterms', true, 10000);
    } else {
        setCookie('customerterms', false, 10000);
    }


    customercomments = $("#customercomments").val();
    setCookie("customercomments", customercomments, 10000);

    // Populate the fields
    $('#CVR').text(getCookie('CVR'));
    $('#CustomerAddress').text(getCookie('CustomerAddress'));
    $('#CustomerCity').text(getCookie('CustomerCity'));
    $('#CustomerCompanyName').text(getCookie('CustomerCompanyName'));
    $('#CustomerContactPerson').text(getCookie('CustomerContactPerson'));
    $('#CustomerEmail').text(getCookie('CustomerEmail'));
    $('#CustomerPhone').text(getCookie('CustomerPhone'));
    $('#CustomerPostalCode').text(getCookie('CustomerPostalCode'));
    $('#paymentMethod').text(getCookie('paymentMethod'));

    $('.dynamic-cardType').text(getCookie('cardType'));
    $('.dynamic-cardAmount').text(cardAmount);

    $.validator.setDefaults({
        ignore: [] // DON'T IGNORE PLUGIN HIDDEN SELECTS, CHECKBOXES AND RADIOS!!!
    });

    $('.step5__form').validate({
        errorPlacement: function (error, element) {
            $('p.' + error.attr('id')).html(error).show();
        },
        rules: {
            terms: {
                required: true
            }
        },
        messages: {
            terms: {
                required: "Du skal accepterer kortbestemmelserne."
            }
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

}