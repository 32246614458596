if ($('.step4').length) {
    var onlineDiscount = getCookie("onlineDiscount");
    var invoiceEmail = getCookie("invoiceEmail");
    var invoicePost = getCookie("invoicePost");
    var productDiscount = getCookie("productDiscount");
    var invoiceSplit = decodeURI(getCookie("invoiceSplit"));
    var cashDiscount = getCookie("cashDiscount");
    var campaignDiscount = getCookie("campaignDiscount");
    var literAmount = getCookie("literAmount");
    var toppedup = getCookie("toppedup");
    var oilTypeName = getCookie('oilTypeName') || 'Basis';
    var pricePer1kLiter = getPricePer1kLiter();
    updatePrices(literAmount, pricePer1kLiter, oilTypeName);
    function makeid() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 6; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length)) ;

        return text + Date.now();
    }
    if (invoiceEmail == "true") {
        
        //hide invoice price
    }

    var order_id = makeid();
    setCookie('order_id', order_id, 10000);     
    var altDelivery = parseInt(getCookie('altDelivery'));
    var toppedUp = getCookie('toppedUp');
    var invoicePrice = getCookie('invoicePrice');
    var paymentMethod = getCookie('paymentMethod') || 'invoice';
    var totalPrice = getCookie('totalPrice');
    $('.dynamic-TotalSplit3').text(numeral(totalPrice / 3).format("0,0.00"));
    $('.dynamic-TotalSplit6').text(numeral(totalPrice / 6).format("0,0.00"));
    if (paymentMethod == 'invoice') {
        paymentMethodText = 'faktura';
    } else {
        paymentMethodText = paymentMethod;
    }

    if (invoicePost == "false") {
        $('.invoicepost').hide();
    }
    if (toppedUp == "" || toppedUp == "false") {
        $('#automaticDeliveryOption').hide();
    }

    if (altDelivery) {
        $('#deliveryAddress').text(getCookie('DeliveryAddressStreet'));
        $('#deliveryPostCode').text(getCookie('DeliveryAddressPostCode'));
        $('#deliveryCity').text(getCookie('DeliveryAddressCity'));
    } else {
        $('#deliveryAddress').text(getCookie('PersonalAddressStreet'));
        $('#deliveryPostCode').text(getCookie('PersonalAddressPostCode'));
        $('#deliveryCity').text(getCookie('PersonalAddressCity'));
    }    

    $('#order_id').text(getCookie('order_id'));
    $('#ContactPersonEmail').text(getCookie('ContactPersonEmail'));
    $('.dynamic-ContactPersonFirstName').text(getCookie('ContactPersonFirstName'));
    $('#ContactPersonLastName').text(getCookie('ContactPersonLastName'));
    $('#ContactPersonMobilePhone').text(getCookie('ContactPersonMobilePhone'));
    $('#CustomerPersonalIDNumber').text(getCookie('CustomerPersonalIDNumber'));
    $('#DeliveryContactFirstName').text(getCookie('DeliveryContactFirstName'));
    $('#DeliveryContactLastName').text(getCookie('DeliveryContactLastName'));
    $('#literAmount').text(getCookie('literAmount')); 
    $('#oilTypeName').text(getCookie('oilTypeName') || 'Basis');
    if (paymentMethodText == "faktura") {
        invoiceSplit = getCookie('invoiceSplit');
        if (invoiceSplit == "Ja tak, jeg ønsker 3 betalinger") {
            paymentMethodText = " " + paymentMethodText + ". Fakturaen er opdelt i 3 betalinger á {{price}} kr. pr. md";
            paymentMethodText = paymentMethodText.replace("{{price}}", numeral(totalPrice / 3).format("0,0.00"))
        }
        else if (invoiceSplit == "Ja tak, jeg ønsker 6 betalinger") {

            paymentMethodText = paymentMethodText + " " + "Fakturaen er opdelt i 6 betalinger á {{price}} kr.pr.md";
            paymentMethodText = paymentMethodText.replace("{{price}}", numeral(totalPrice / 6).format("0,0.00"))
        }
        else {
            paymentMethodText = paymentMethodText;
        }
      
        $('#paymentMethod').text(paymentMethodText);

        $('#invioceprice').text(getCookie('invoicePrice'));
    }
    else {
        $('#paymentMethod').text(paymentMethodText);
    } 
    $('#pricePer1kLiter').text(getPricePer1kLiter());
    $('#tankSize').text(getCookie('tankSize'));
    $('.dynamic-DeliveryDate').text($('#enddate').text());
    var DeliveryDate = $('#enddate').text();
    setCookie('DeliveryDate', DeliveryDate, 1000);

    $.validator.setDefaults({
        ignore: [] // DON'T IGNORE PLUGIN HIDDEN SELECTS, CHECKBOXES AND RADIOS!!!
    });

    $('.step4__form').validate({
        errorPlacement: function (error, element) {
            $('p.' + error.attr('id')).html(error).show();

            if (element.attr("name") == "terms") {
                $('.review__terms').addClass('error');   
            }
        },
        rules: {
            terms: {
                required: true
            },
            automaticDelivery: {
                required: '#automatic:checked',
                digits: true
            },
            emailRegistration: {
                required: '#email:checked',
                digits: true
            }
        },
        messages: {
            terms: {
                required: "Du skal acceptere salgs- og leveringsbetingelserne."
            },
            automaticDelivery: {
                required: "Du har valgt Automatisk Levering og skal derfor udfylde M<sup>2</sup>.",
                digits: "Der må kun udfyldes tal."
            },
            emailRegistration: {
                required: "Du har valgt at modtage email når der skal opfyldes igen og skal derfor udfylde M<sup>2</sup>.",
                digits: "Der må kun udfyldes tal."
            }
        },
        submitHandler: function (form) {
            var squareMeters = 0;
            var SquareMetersEmailNotification = 0;

            if (form.automaticDelivery.value.length > 0) {
                squareMeters = form.automaticDelivery.value;
            };

            if (form.emailRegistration.value.length > 0) {
               SquareMetersEmailNotification = form.emailRegistration.value;
            }
            setCookie('squareMeters', squareMeters);
            setCookie('SquareMetersEmailNotification', SquareMetersEmailNotification);
            if (paymentMethod == "kreditkort" || paymentMethod == "mobilepay") {
                quickpay();
               
                var x = getCookie("salesforceId");
            } else if (paymentMethod == "invoice") {
                salesforce();
                var x = getCookie("salesforceId");
            } 

            if ($('#automatic').is(':checked')) {
                setCookie('automatic', true, 10000);
                setCookie('email', false, 10000);
            } else {
                setCookie('automatic', false, 10000);
            }

            if ($('#email').is(':checked')) {
                setCookie('email', true, 10000);
                setCookie('automatic', false, 10000);
            } else {
                setCookie('email', false, 10000);
            }
            if ($('#terms').is(':checked')) {
                setCookie('terms', true, 10000);
            } else {
                setCookie('terms', false, 10000);
            }


            comments = $("#comments").val();
            setCookie("comments", comments, 10000);
        }    
    }); 

    $('#automatic').change(function () {
        if ($(this).is(':checked')) {
            $('#email').prop('checked', false);
            setCookie("automatic", true, 10000);
            setCookie('email', false, 10000);

        } else {
            setCookie("automatic", false, 10000);
        }
    });

    $('#email').change(function () {
        if ($(this).is(':checked')) {
            $('#automatic').prop('checked', false);
            setCookie("email", true, 10000);
            setCookie('automatic', false, 10000);

        } else {
            setCookie("email", false, 10000);
        }
    });

}
