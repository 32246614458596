
var paymentMethod = getCookie('paymentMethod');
var order_id = getCookie('order_id');
function quickpay() {
 
    var amount = getCookie('totalPrice')*100; // Dynamic price calculation here
    var currency = 'dkk';
    var localUrl = document.querySelector('.progress__labels li:last-child').dataset.url;
    var data = {
        "order_id": order_id,
        "currency": currency,
      //  'continue_url': window.location.origin + localUrl
    };
    
    if (paymentMethod == 'kreditkort') {
        paymentMethod = 'visa,mastercard,dankort';
    } else {
        paymentMethod = 'mobilepay';
    }

    $.ajax({  
        type: "POST",
        url: '/Umbraco/Api/Quickpay/quickpay',
        data: data,  
        dataType: "json",
        success: function (x) {
            
            x = JSON.parse(x);
            var paymentid = x.id;
            setCookie('paymentid', paymentid , 10000);
      
            var data = {
                'id': paymentid,
                'amount': amount,
                'continue_url': window.location.origin + localUrl,               
                'cancelurl': window.location.origin + '/order-page/godkend-ordre/' ,
                'payment_methods': paymentMethod   
            };

            $.ajax({
                type: "PUT",
                url: '/Umbraco/Api/Quickpay/PaymentLink',
                data: data,
                dataType: "json",
                success: function (result) {
                    try {    
                        //salesforce();
                        result = JSON.parse(result);
                        location = result.url;
                       
            
                    } catch(e) {
                        alert(e.message);
                        console.log(e);
                    }
                },
                error: function (xhr, thrownError) {
                    alert(xhr.status);
                    alert(thrownError);
                }
              
            });
        },
    });  
}
