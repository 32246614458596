$(document).ready(function () {
    $("#nextButton").click(function () {
        var cardAmount = getCookie('cardAmount');
        var cardType = getCookie('cardType');
        var data = [];
        for (var i = 1; i <= cardAmount; i++) {
            var cardTypeValue = $("#card-" + i).val();
            var eurocardtext = $("#" + cardType + "-extratext-" + i).val();
            var meter = $("#meter-" + cardType + "-" + i).prop('checked');
            var driver = $("#driver-solution-" + cardType + "-" + i).prop('checked');
            data.push({
                cardType: cardTypeValue,
                eurocardtext: eurocardtext,
                meter: meter,
                driver: driver,
            });
        }
        setCookie("cardvalue", JSON.stringify(data), 10000);

        var file = $('#csvFileInput')[0].files[0];
        if (file) {
            var data = new FormData();
            data.append('file', file);
            $.ajax({
                url: '/Umbraco/Api/CutomerFileUpload/Upload',
                processData: false,
                contentType: false,
                data: data,
                type: 'POST'
            }).done(function (result) {
                alert(result);
            }).fail(function (a, b, c) {
                console.log(a, b, c);
            });
        }
    });

})

if ($('.customerStep2').length) {
    // Checking for cookies and populating the fields
    var cardType = getCookie('cardType');

    if (cardType.length < 1) {
        cardType = $('input[name="cardType"]:checked').val();
        setCookie('cardType', cardType, 10000);
    }

    if (cardType.length > 0) {
        $('input[value="' + cardType + '"]').prop('checked', true);
        $('.cards__choose').removeClass('hide');

        $('.cards__types .grid-x').addClass('hide');
        $('[data-card-type="' + cardType + '"]').removeClass('hide');
    }

    if (cardAmount > 0) {
        $('.cards__amounts label:eq(' + (cardAmount - 1) + ')').addClass('active');
        $('.cards__types').removeClass('hide');

        if (cardAmount < 6) {
            $('.cards__upload').addClass('hide');

            $('.cards__types .grid-x').each(function (index) {
                for (i = 0; i < cardAmount; i++) {
                    $('.cards__types .grid-x:eq(' + index + ') .cards__card-info:eq(' + i + ')').addClass('active');
                }
            });
        } else {
            $('.cards__card-info').removeClass('active');
            $('.cards__upload').removeClass('hide');
        }
    }

    if (cardAmount > 1 && cardAmount < 6) {
        $('.dynamic-cardAmount').show();
        $('.dynamic-cardAmount').text(cardAmount);
    } else if (cardAmount == 1 || cardAmount == 6) {
        $('.dynamic-cardAmount').hide();
    }

    $('[data-card-type="' + cardType + '"] select').each(function () {
        //$('option[value="' + getCookie($(this).attr('name')) + '"]', this).prop('selected', true);
        $('option[value="' + getCookiePrototype($(this).attr('name')) + '"]', this).prop('selected', true);
    });

    $('[data-card-type="' + cardType + '"] input[type="text"]').each(function () {
        //$(this).val(getCookie($(this).attr('name')));
        $(this).val(getCookiePrototype($(this).attr('name')));
    });

    $('[data-card-type="' + cardType + '"] input[type="checkbox"]').each(function () {
        if (parseInt(getCookiePrototype($(this).attr('name')))) {
            //$(this).prop('checked', true);
            $(this).prop('checked', true);
        }
    });

    // Functionality for clicking through the step
    $('input[name="cardType"]').change(function () {
        cardType = $(this).val();

        // $('.cards__choose').removeClass('hide');
        setCookie('cardType', cardType, 10000);

        $('.cards__types .grid-x').addClass('hide');
        $('[data-card-type="' + cardType + '"]').removeClass('hide');
    });

    $('.cards__amounts label').click(function () {
        cardAmount = $(this).data('amount');
        setCookie('cardAmount', cardAmount, 10000);
        $('input[name="cardAmount"]').val(cardAmount);

        if (cardAmount > 1 && cardAmount < 6) {
            $('.dynamic-cardAmount').show();
            $('.dynamic-cardAmount').text(cardAmount);
        } else if (cardAmount == 1 || cardAmount == 6) {
            $('.dynamic-cardAmount').hide();
        }

        $('.cards__amounts .active').removeClass('active');
        $(this).addClass('active');

        $('.cards__types').removeClass('hide');
        $('.cards__card-info').removeClass('active');

        if (cardAmount < 6) {
            $('.cards__upload').addClass('hide');

            $('.cards__types .grid-x').each(function (index) {
                for (i = 0; i < cardAmount; i++) {
                    $('.cards__types .grid-x:eq(' + index + ') .cards__card-info:eq(' + i + ')').addClass('active');
                }
            });
        } else {
            $('.cards__upload').removeClass('hide');
        }
    });

    $('.cards__driver-solution').change(function () {
        if ($(this).is(':checked')) {
            $(this).parent().parent().children('.cards__checkbox').children('.cards__meter').prop({
                'disabled': true,
                'checked': true
            });
        } else {
            $(this).parent().parent().children('.cards__checkbox').children('.cards__meter').prop({
                'disabled': false
            });
        }
    });

    $.validator.setDefaults({
        ignore: [] // DON'T IGNORE PLUGIN HIDDEN SELECTS, CHECKBOXES AND RADIOS!!!
    });

    $('.customerStep2__form').validate({
        errorPlacement: function (error, element) {
            $('p.' + error.attr('id')).html(error).show();
        },
        rules: {
            cardType: {
                required: true
            },
            cardAmount: {
                required: true
            }
        },
        messages: {
            cardType: {
                required: 'De skal huske at vælge en korttype.'
            },
            cardAmount: {
                required: 'De skal vælge hvor mange kort de ønsker at bestille.'
            }
        },
        submitHandler: function (form) {
            console.log('Success');

            var cardAmount = $('.cards__amounts .active').data('amount');

            // Save select value to cookie
            $('[data-card-type="' + form.cardType.value + '"] select').each(function (i) {
                value = '';

                if (i < cardAmount && cardAmount < 6) {
                    value = $(this).val();
                }
                //setCookie($(this).attr('name'), value, 10000);
                setCookiePrototype($(this).attr('name'), value, 10000);
            });

            // Save Extra text to cookie
            $('[data-card-type="' + form.cardType.value + '"] input[type="text"]').each(function (i) {
                var value = '';

                if (i < cardAmount && cardAmount < 6) {
                    var value = $(this).val();
                }
                //setCookie($(this).attr('name'), value, 10000);
                setCookiePrototype($(this).attr('name'), value, 10000);
            });

            // Save checkbox value to cookie
            $('[data-card-type="' + form.cardType.value + '"] input[type="checkbox"]').each(function (i) {
                var value = 0;
                var multiplier = 1;

                if (form.cardType.value == "Europecard") {
                    multiplier = 2;
                }

                if (i < (cardAmount * multiplier) && cardAmount < 6) {
                    if ($(this).is(':checked')) {
                        value = 1;
                    }
                }
                //setCookie($(this).attr('name'), value, 10000);
                setCookiePrototype($(this).attr('name'), value, 10000);
            });

            form.submit();
        }
    });
}