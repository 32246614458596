// Function to check if a value is in the array.
function isInArray(value, array) {
    return array.indexOf(value) > -1;
}

// Function for changing the active picture in the hero element
function heroImageUpdate(ticks) {
    var sliderValue = parseInt($('.slider__value').val());
    if (isInArray(sliderValue, ticks)) {
        index = ticks.indexOf(sliderValue);
        $('.hero__image-sequence .active').removeClass('active');
        $('.hero__image:eq(' + index + ')').addClass('active');
    } else {
        var i = 0;
        while (i < ticks.length && ticks[i] < sliderValue) {
            index = ticks.indexOf(ticks[i]);
            $('.hero__image-sequence .active').removeClass('active');
            $('.hero__image:eq(' + index + ')').addClass('active');
            i++;
        }
    }
}

if ($('.landingpage').length) {
    if (getCookie('literAmount').length < 1) {
        setCookie('literAmount', 1000, 10000);
    }
}

// Function for creating list of ticks
function createTicksArray() {
    var ticks = [];

    $('.hero__image').each(function () {
        ticks.push($(this).data('amount'));
    });

    return ticks;
}

// If hero slider is also present
if ($('.hero__image-sequence').length) {
    var ticks = createTicksArray();

    $('.slider__range').on('input change', 'input', function () { heroImageUpdate(ticks) });
}

// Update totals in slider/cart.
if ($('.oilSlider').length) {
    // Set numeral to Danish price structure. Documentation for Numeral: http://numeraljs.com/
    numeral.locale('da-dk');


    // Get the amount of liters from the range slider, if value is not set, get the min value from the range slider.
    var literAmount = getCookie("literAmount");
    if (literAmount.length == 0) {
        literAmount = $('.slider__value').attr('min');
    } else {
        $('.slider__value').val(literAmount);
    }
    // Get the oil type name.
    var oilTypeName = $('input[name="oiltype"]:checked').parent().data('oil-type-name');
    // Get the prices per 1,000 liter of oil.
    var pricePer1kLiter = numeral($('input[name="oiltype"]:checked').parent().data('oil-type-price')).value() / 1000;
    var onlineDiscount = $('.dynamic-onlineDiscount').text();
    var productDiscount = $('.dynamic-productDiscount').text();
    var cashDiscount = $('.dynamic-cashDiscount').text();
    var campaignDiscount = $('.dynamic-campaignDiscount').length ? $('.dynamic-campaignDiscount').text():'0';

    // Update the data in the cart.
    updatePrices(literAmount, pricePer1kLiter, oilTypeName);

    // Set toppedUp if needed
    var toppedUp = getCookie('toppedUp') == 'true';

    if (toppedUp) {
        $('#toppedUp').attr('checked', 'true');
    }

    // Update data in the cart when the range slider is in use.
    $('.slider__range').on('input change', 'input', function () {
        literAmount = $('.slider__value').val();
        pricePer1kLiter = numeral($('input[name="oiltype"]:checked').parent().data('oil-type-price')).value() / 1000;

        updatePrices(literAmount, pricePer1kLiter, oilTypeName);

        setCookie("literAmount", literAmount, 10000);
        setCookie("oilTypeName", oilTypeName, 10000);
    });

    // Update the data in the cart when product type is being changed.
    $('input[name="oiltype"]').change(function () {
        literAmount = $('.slider__value').val();
        pricePer1kLiter = numeral($('input[name="oiltype"]:checked').parent().data('oil-type-price')).value() / 1000;
        oilTypeName = $('input[name="oiltype"]:checked').parent().data('oil-type-name');

        updatePrices(literAmount, pricePer1kLiter, oilTypeName);

        setCookie("literAmount", literAmount, 10000);
        setCookie("oilTypeName", oilTypeName, 10000);
    });

    // Set a cookie if Topped Up is checked
    $('#toppedUp').change(function () {
        if ($(this).is(':checked')) {
            setCookie("toppedUp", true, 10000);
            if (getCookie('paymentMethod') != 'invoice') {
                setCookie('paymentMethod', '', 10000);
            }
        } else {
            setCookie("toppedUp", false, 10000);
        }
    });

    
}
