if ($('.youtube').length) {
    var tag = document.createElement('script');
    var youtubePlayers = document.querySelectorAll('.youtube');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    


    var player = [];
    function onYouTubeIframeAPIReady() {
        for (j = 0; j < youtubePlayers.length; j++) {
            var videoId = youtubePlayers[j].dataset.youtubeLink.split('?v=');
            videoId = videoId[1];

            player = new YT.Player(youtubePlayers[j], {
                height: '390',
                width: '640',
                videoId: videoId,
            });
        }

        function onPlayerReady(event) {
            event.target.playVideo();
        }
    }

    
}