$(document).ready(function () {

    setTimeout(function () {
        if ($('.cookiescript').length) {
            $('.cookiescript').html('<script id="CookiePolicy" src="https://policy.app.cookieinformation.com/cid.js" data-culture="DA" type="text/javascript"></script>');
        }
    }, 100);

    if ($('.advantages-section__links').length) {

        $('.advantages-section__links .owl-wrap').owlCarousel({
          //loop:true,
          items:1,
          margin:15,
          nav:true,
          mouseDrag:false,
          touchDrag:true,        
          navText: [
            "<i class='icon-down-open-big'></i>",
            "<i class='icon-down-open-big'></i>"
        ],
          //center:true,
          responsive:{
              0:{
                  items:3,
                  stagePadding: 0,
                  margin:0,
              },
              475:{
                items:3
              },
              575:{
                  items:4
              },
              1000:{
                  items:5
              }
          }
      })

      $(".advantages-section__links .owl-item").each(function(i) {
          $(this).find("span div").html(++i);
      });

    }
    
    if ($('.advantages-section').length) {
        $('.advantages-section a').click(function (e) {
            e.preventDefault();
            var aid = $(this).attr("href");
            $('html, body').animate({
                scrollTop: $(aid).offset().top
            }, 500);
        });
    }

});

