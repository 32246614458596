if ($('.oilStep1').length) {
    var oilTypeName = getCookie('oilTypeName') || 'Basis';
    
    formatBasicPrices();

    var productChoice = parseInt(getCookie('productChoice'));
    var toppedUp = getCookie('toppedUp') == 'true';
    if (toppedUp) {
        $('#toppedUp').attr('checked', 'true');
    }

    if (oilTypeName.length > 0 && productChoice) {
        $('*[data-oil-type-name="' + oilTypeName + '"] input').prop("checked", true);
        updatePrices(getCookie('literAmount'), parseInt($('input[name="oiltype"]:checked').parent().data('oil-type-price')) / 1000, oilTypeName);
    }

    updateOilPrice(getCookie('literAmount'), parseInt($('input[name="oiltype"]').parent().data('oil-type-price')) / 1000, oilTypeName);

    $('input').each(function () {
        nicelabelCheck($(this));
    });

    $('.step1__form').validate({
        errorPlacement: function (error, element) {
            $('p.' + error.attr('id')).html(error).show();
        },
        submitHandler: function (form) {
            var literAmount = $('.slider__value').val();
            setCookie("productChoice", 1, 10000);
            form.submit();
        }
    });
}

function formatBasicPrices() {
    var prices = Array.from($(".oiltype__body-price"));

    prices.forEach(function(price) {
        sPrice = Array.from(price.innerText);
        overThousand = sPrice.splice(-3);
        price.innerText = sPrice.join("") + "." + overThousand.join("");
    });
    
}
